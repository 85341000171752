@mixin upsell {
  color: black;
  font-size: 15px;
}

@mixin title($fontSize) {
  font-weight: var(--font-weight-bold);
  font-size: $fontSize;
  line-height: 1.1;
  font-weight: bold;
}

@mixin button($height, $fontSize) {
  height: $height;
  
  span {
    font-size: $fontSize;
  }
}

@mixin list {
  margin: 10px 0 5px;
}

.dialogBody {
  padding: 30px 30px 15px;
}

.inkDialogBody {
  background-color: var(--secondary-color-5);
  background-image: url('/assets/img/premium/background-soundwave.png');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: contain;
  color: #fff;
  padding: 30px 30px 15px;
}

.dialogTitle {
  border: none;
  text-align: center;
}

.linkWrapper {
  display: block;
  margin-top: 5px;
  text-align: center;
  width: 100%;

  @media (--min-width-xxsmall) {
    margin-top: 10px;
  }
}

.link {
  cursor: pointer;
  font-size: var(--font-size-2);
  border-bottom: 2px solid #000;
  padding-bottom: 2px;
  color: black;

  &:hover {
    text-decoration: none;
    color: var(--primary-color-1);
    border-bottom: 2px solid var(--primary-color-1);
  }
}

.bgImage {
  display: none;

  @media (--min-width-medium) {
    display: block;
    background-size: 100%;
    background-image: url('/assets/img/premium/premium-logos-v6.png');
    height: 245px;
    width: 178px;
    position: absolute;
    top: 40px;
    right: 10px;
    z-index: 1;
  }
}

.closeButton {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1;
  font-size: var(--font-size-3);
  cursor: pointer;
  color: black;
}

.closeButtonSvg {
  position: absolute;
  top: 12px;
  right: 12px;
  background: transparent url('/assets/img/shared/close-button.svg') no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  z-index: 1;
  cursor: pointer;
}

.dialogWrapper {
  @include upsell;

  padding: 0 10px 20px;
  color: var(--secondary-color-5);

  @media (--min-width-xsmall) {
    padding: 0 15px 20px;
  }

  @media (--min-width-small) {
    padding: 5px 35px 30px;
  }

  @media (--min-width-medium) {
    padding: 5px 15px 30px;
  }

  .title {
    @include title(24px);

    @media (--min-width-xsmall-1) {
      font-size: 37px;
    }

    @media (--min-width-small) {
      font-size: 45px;
      line-height: 1.17;
    }

    @media (--min-width-medium) {
      font-size: 41px;
    }
  }

  .subtitle {
    @include title(21px);
  }

  .content {
    line-height: 1.4;

    @media (--min-width-xxsmall) {
      padding-bottom: 12px;
    }

    @media (--min-width-medium) {
      padding-bottom: 18px;
      max-width: 270px;
      min-height: 270px;
    }
  }

  .list {
    @include list;

    font-size: var(--font-size-2);

    td {
      padding: 5px 0;
            font-weight: bold;
    }

    @media (--min-width-xsmall-1) {
      margin: 20px 0;
      font-size: var(--font-size-4);
    }

    .checkmark {
      margin-right: 10px;
    }
  }

  .button {
    span {
      font-size: 15px;

      @media (--min-width-xxsmall) {
        font-size: 17px;
      }
    }
  }

  .signUpUpsellHeader {
    @include title(15px);

    text-align: center;
    margin-bottom: 11px;

    @media (--min-width-xsmall-1) {
      @include title(22px);
    }
  }

  .signUpUpsellContent {
    @include title(18px);

    @media (--min-width-xsmall-1) {
      @include title(30px);
    }
  }

  .customUpsellContent {
    line-height: 1.4;
    text-align: center;

    @media (--min-width-medium) {
      text-align: left;
      max-width: 420px;
    }

    .guideImageContainer {
      display: block;

      @media (--min-width-medium) {
        display: inline-block;
        margin-right: 24px;
      }
    }

    .guideImage {
      margin: auto;
      width: 146px;
      height: 146px;
      vertical-align: middle;
    }

    .premiumLogoXSmall,
    .premiumLogoSmall {
      color: white;
      font-family: var(--primary-font-header-bold);
      background-color: var(--tunein-coral);
      border-radius: 2px;
      font-size: var(--font-size-1);
      padding: 6px 8px;
      margin-bottom: 8px;
      line-height: 0.8;
    }

    .premiumLogoSmall {
      display: none;

      @media (--min-width-medium) {
        display: inline-block;
      }
    }

    .premiumLogoXSmall {
      display: inline-block;

      @media (--min-width-medium) {
        display: none;
      }
    }

    .guideTitleContainer {
      display: block;
      vertical-align: middle;
      margin: 16px;

      @media (--min-width-medium) {
        display: inline-table;
        max-width: 245px;
        margin: 0;
      }

      .title {
        font-weight: var(--font-weight-bold);
        font-size: 26px;
        font-weight: 600;
        line-height: 1.12;
        color: #fff;
      }
    }

    .premiumOfferingContainer {
      font-size: var(--font-size-4);
      line-height: 1.33;
      color: #fff;
      margin: 24px 0;
      display: none;

      @media (--min-width-medium) {
        display: block;
      }
    }
  }
}

.sidebarWrapper {
  @include upsell;

  pointer-events: auto;
  font-size: var(--font-size-2);
  padding: 30px 16px 80px;
  color: var(--secondary-color-5);

  &.isProfile {
    padding: 30px 0 80px;
  }

  &.newDesign {
    background-color: var(--secondary-color-5);
    background-image: url('/assets/img/premium/background-circles.jpg');
    background-position: top right;
    background-repeat: no-repeat;
    color: white;
    padding: 20px 0 30px;
    margin: 15px 0 80px;
    float: right;
  }

  .newUpsellLogosContainer {
    background-image: url('/assets/img/premium/premium-logos-v1.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 300px;
    height: 150px;
  }

  .title {
    @include title(25px);

    &.newDesign {
      color: var(--premium-gold-light);
      padding-top: 20px;
      text-align: center;
      font-size: var(--font-size-7);
    }
  }

  .list {
    /* @include list; */
    margin: 5px 0 10px;
    max-width: 265px;
    line-height: 20px;

    &.newDesign {
      margin: 10px 30px;
    }

    td {
      padding: 3px 0;
      font-size: var(--font-size-3);
    }

    .checkmark {
      margin-right: 8px;
    }
  }

  .buttonLinkWrapper {
    width: 170px;
    display: block;
    text-decoration: none;

    &.newDesign {
      margin: 10px auto;
    }
  }

  .button {
    @include button(36px, 14px);

    &.newDesign {
      background-color: var(--premium-gold-light);
      color: var(--ink-dark);

      span {
        font-size: var(--font-size-2);
      }
    }
  }
}
